import React,{useEffect,useState} from 'react';
import { HiUser } from 'react-icons/hi';
import { BsArrowRight } from 'react-icons/bs';
import { NavLink, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import lotti from '../assets/animation_llaix5r2.json';
import Lottie from "lottie-react";

const CategoriesData = () => {

  const [card, setCard] = useState([]);

  const [name, setname] = useState("");

  const [Id , setId] = useState();
  console.log(name);

  let Navigate = useNavigate();

    const Api = ()=>{
      try {
        var formdata = new FormData();
        formdata.append("token", localStorage.getItem("token"));

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };

        fetch("https://www.scraponwheels.com/api/admin/category", requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result);
          if(result.status=="001"){
            setCard(result.message);
          }})
          .catch(error => console.log('error', error));
      } catch (error) {
        
      }
    }

    const AddCat = ()=>{
      var formdata = new FormData();
      formdata.append("token", localStorage.getItem("token"));
      formdata.append("name", name);

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch("https://www.scraponwheels.com/api/admin/category_add", requestOptions)
        .then(response => response.json())
        .then(result => {console.log(result)
        if(result.status=="001"){
          Api();
          toast(result.message);
           
        }})
        .catch(error => console.log('error', error))
        .finally(()=>{setname([])});   
    }

    const Delete = (id) => {
  
        var formdata = new FormData();
        formdata.append("token", localStorage.getItem("token"));
        formdata.append("id", id);

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };

        fetch("https://www.scraponwheels.com/api/admin/category_disable", requestOptions)
          .then(response => response.json())
          .then(result => {

            if(result.status == "001"){
              Api();
              toast(result.message);
            }
             else{
              toast(result.message);
            }
            
          })
          .catch(error => console.log('error', error));

    }

  useEffect (()=>{
    Api();
  },[]);

  useEffect(() => {
    if(!localStorage.getItem("token")){
        Navigate("/");
    }
    else{
      
    }
  }, []);

  return (
    <>
    <ToastContainer/>
        <div className='d-flex justify-content-end align-items-center mt-4' >
        <HiUser className='userIcon p-2'/>
      </div> 

      <div className='d-flex align-items-center justify-content-between mt-5' >
        <h1 className='vendorHeading' >Categories </h1>
        <span className='p-2 vendorButton2' data-bs-toggle="modal" data-bs-target="#staticBackdropModal" >+ Add Category</span>
        </div>
        <hr></hr>


        <div className='container-fluid' >
            <div style={{height:'65vh',overflowY:'scroll'}} >
            <div className='row'>
            {
              card?.length>0?
                card.map((item,res)=>{
                  return(
                <div key={res} className='col-lg-3 mt-3' >
                <div class="card" style={{width:'100%'}}>
                    <div class="card-body">
            <NavLink to={`/Electronics/${item.id}/${item.name}`} style={{textDecoration:'none'}} >
                      <div className='row'>
                        <div className='col-9'>
                        <h5 class="card-title"  style={{fontFamily:'Inter',fontStyle:'normal',fontSize:'24px',color:'#000'}} >{item.name}</h5>
                        <p class="card-text" style={{fontFamily:'Inter',fontStyle:'normal',fontSize:'16px',color:'#000'}} >SubCategories : {item.subcategory_count}</p>
                   
                        </div>
                        <div className='col-3'>
                       <h4><BsArrowRight style={{color:'#000'}}/></h4>
                        </div>
                      </div>
                </NavLink>

                      <div>
                      <button className='mt-3' data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{backgroundColor:'#ffdbdf',color:'red',border:'none',
                      borderRadius:'8px',padding:'4px 8px', width:'100%'}} onClick={()=>{setId(item.id)}}>Delete </button>

                      </div>
                        
                   
                    </div>
                    </div>
                </div>
                )
                }):<div>
                <div className='d-flex ' style={{justifyContent:"center",alignItems:"center"}}>
       <Lottie animationData={lotti} style={{width:"50%",height:"15rem",marginLeft:"0rem",}}/>
                  </div>
                {/* <h6 style={{color:"red"}}>No Data Found</h6> */}
                </div>
               }
               </div>
            </div>
        </div>







        {/* modal */}


<div class="modal fade" id="staticBackdropModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel" style={{fontFamily:'Inter',fontSize:'82px !important',color:'#272727'}} >Add Category</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{color:'#333333'}} ></button>
      </div>
      <div class="modal-body">
        <h6 style={{fontFamily:'Inter',fontStyle:'normal',fontSize:'24px',color:'#292929'}} >Enter Name Of Category</h6>

        <div className='p-3' >
            <input type='text' className='px-3 py-2' placeholder='Start Typing Category Name... ' style={{width:'100%',background:'#FAFAFA',borderRadius:'5px',border:'none'}} onChange={(e)=>{setname(e.target.value)}} />
            
        </div>
        <div className='col-5 m-auto mt-3' >
            <button className='py-2' data-bs-dismiss="modal" aria-label="Close" onClick={()=>{AddCat()}} style={{backgroundColor:'#040404',borderRadius:'5px',color:'#fff',fontFamily:'Roboto',fontStyle:'normal',fontSize:'14px',width:'100%',border:'none'}} >Confirm</button>

        </div>
      </div>
      
    </div>
  </div>
</div>


{/* delete modal start */} 


<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style={{backdropFilter:'blur(8px)'}}>
  <div class=" modal-dialog modal-dialog-centered" >
    <div class="modal-content">
      <div class="modal-header border-0"> 
      </div>
      <div class="modal-body">
        <h6 className='mb-5 text-center'>Are you sure you want to delete this category ?</h6>
        <div className='mb-4' style={{display:'flex', justifyContent:'space-around'}}>
          <button data-bs-dismiss="modal" aria-label="Close" style={{backgroundColor:'#ffc4c4', color:'red', padding:'5px 30px',borderRadius:'8px',border:'none'}} onClick={()=>{Delete(Id)}}>Yes</button>
          <button data-bs-dismiss="modal" aria-label="Close" style={{backgroundColor:'#d2d2d2', color:'#000', padding:'5px 30px',borderRadius:'8px',border:'none'}}>Cancel</button>
        </div>
      </div>
      
    </div>
  </div>
</div>
{/* delete modal end */}
    </>
  )
}

export default CategoriesData

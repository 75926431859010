import React,{useEffect} from 'react'
import Sidebar from '../Sidebar'
import "./Vendors.css";
import VendorsData from "./VendorsData"
import { useNavigate } from 'react-router-dom';

const Home = () => {

   let Navigate = useNavigate();

  useEffect(() => {
    if(!localStorage.getItem("token")){
        Navigate("/");
    }
    else{
      
    }
}, []);
  return (
   <>
        <div className='container-fluid' >
          <div className='row'>
            <div className='col-lg-2 sidebar' >
              <Sidebar/>
            </div>

            <div className='col-lg-10' >
              <VendorsData/>
            </div>
          </div>
        </div>
   </>
  )
}

export default Home

import React,{useEffect,useState} from 'react';
import {HiUser} from "react-icons/hi";
import Sidebar from '../Sidebar';
import { IoIosArrowRoundBack } from 'react-icons/io'
import { NavLink, Route, Routes, useParams } from 'react-router-dom'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    
    const [rate, setrate] = useState("");
   
    const {id} = useParams();
   
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    console.log(index);
    if(index==2){
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }
    else{
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }
    
  }
  

const RetElectronics = () => {

  const [name11, setname] = useState("");
 // console.log(name1)
 const [Siunit, setSiunit] = useState("")

  const [name1, setname1] = useState("");
  console.log(name1)

  const [name2, setname2] = useState("");
  console.log(name2)
   

  const [District, setDistrict] = useState();
 
    let navigate = useNavigate();

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const {id,name,RR} = useParams();
    console.log(id,name);
   
    //const [CatName, setCatName] = useState(""); 
    const [card, setCard] = useState([]);

    const Apisubcategory = async() =>{

      try { 
      var formdata = new FormData();
      formdata.append("category_id", id);
        formdata.append("token", localStorage.getItem("token"));
        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };
        
      fetch("https://www.scraponwheels.com/api/admin/subcategory", requestOptions)
          .then(response => response.json())
          .then(result => {console.log("hello1w",result);
          if(result.status=="001"){
            setCard(result.message);
          }})
          .catch(error => console.log('error', error));
        
      } catch (error) {
        console.log(error);
      }
      
    }


    const AddDist = ()=>{
      try {
        var formdata = new FormData();
        formdata.append("token", localStorage.getItem("token"));
        formdata.append("name", name1);
        formdata.append("subcategory_id", District);
        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };


        fetch("https://www.scraponwheels.com/api/admin/add_district", requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result);
          if(result.status=="001"){
            toast(result.message);
          }
        else{
          toast(result.message);
        }})
          .catch(error => console.log('error', error));
      } catch (error) {
        
      }
    }
    
    const ShowLive  = ()=>{
      var formdata = new FormData();
      formdata.append("token", localStorage.getItem("token"));
      formdata.append("category_id", id);

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch("https://www.scraponwheels.com/api/admin/live_rates_items", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log("hello",result);
          if(result.status=="001"){
            setCard1(result.message);
          }
        })
        .catch(error => console.log('error', error));
    }
    

    const AddRetails = async(id)=>{
      var formdata = new FormData();
      formdata.append("token", localStorage.getItem("token"));
      formdata.append("id", id);
      formdata.append("rate", name2);

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch("https://www.scraponwheels.com/api/admin/subcategory_add_rate", requestOptions)
        .then(response => response.json())
        .then(result => {console.log(result);
        if(result.status=="001"){
          setname2("");
          toast(result.message)
        }
      else{
        toast(result.message)
      }})
        .catch(error => console.log('error', error));
    }


  useEffect (()=>{

     if(RR=="Retail Rates"){
       setValue(0);
      }
      else{
        setValue(1);
      } 
      
    },[])

  const [card1, setCard1] = useState([]);
  const [card2, setCard2] = useState([]);

   const RetailsDetials = (id)=>{

    var formdata = new FormData();
    formdata.append("token", localStorage.getItem("token"));
    formdata.append("subcategory_id", id);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://www.scraponwheels.com/api/admin/items_district", requestOptions)
      .then(response => response.json())
      .then(result => {console.log(result);
      if(result.status=="001"){
          setCard2(result.message);
      }
    else{
      toast(result.message);
    }})
      .catch(error => console.log('error', error));
   }

   const AddDisVal = (id)=>{
    var formdata = new FormData();
    formdata.append("token", localStorage.getItem("token"));
    formdata.append("id", id);
    formdata.append("rate", name11);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://www.scraponwheels.com/api/admin/items_district_rate", requestOptions)
      .then(response => response.json())
      .then(result => {console.log(result);
        if(result.status=="001"){
             toast(result.message);
        }
        else{
           toast(result.message);
        }
      })
      .catch(error => console.log('error', error));
   }
 
  useEffect (()=>{
    Apisubcategory();
    
    ShowLive();
  },[]);


  useEffect(() => {
    if(!localStorage.getItem("token")){
      navigate("/");
    }
    else{
      
    } 
  }, []);

  return (
    <>
    <ToastContainer/>
       <div className='container-fluid' >
        <div className='row' >
            <div className='col-lg-2 sidebar' >
                <Sidebar/>
            </div>

            <div className='col-lg-10' >
                <div className='d-flex justify-content-end align-items-center mt-4' >
                <HiUser className='userIcon p-2' />
                </div> 

                <div className='col-lg-12 mt-4' >
                    <div onClick={()=>{
                      if(value==2){
                      setValue(1);
                    }
                    else{
                      navigate("/rates");
                    }
                    }}>
                    <IoIosArrowRoundBack className='backButton' />
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-between' >
                    {value<2?<><h1 className='mainHeading' >
                       {value==1?`Live Rates > ${name}`:`Retail Rates > ${name}`} </h1></>:<></>}
                    {
          value==2?<><button data-bs-toggle="modal" data-bs-target="#staticBackdropDisButtton2" 
          className='vendorButton2 py-1 px-2'  >+Add District</button></>:<></>
        }
                </div>

            <div className='row' >
                <div className='col-lg-12' >
                <Box sx={{ width: '100%' }}>
                {
                  value<2?<><Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab className='tabNavs' label="Retail Rates" {...a11yProps(0)} />
                    <Tab className='tabNavs' label="Live Rates" {...a11yProps(value==2?2:1)} />
                  </Tabs>
                </Box></>:<></>
                }
      
      <TabPanel value={value} index={0}>
      <div  style={{height:'60vh',overflowY:"scroll"}} >
     
            <div className='row'>  
            {
                card.map((item,res)=>{
                  return(
                <div key={res} className='col-lg-3 mt-3' >
                <div className='card myCard' style={{cursor:'pointer'}} >
                    <div className='d-flex justify-content-between align-items-center px-3 mt-2' > 
                        <span className='' style={{fontFamily:'Inter',fontStyle:'normal',fontSize:'16px',color:'#000'}} >{item.name}</span>
                        {/* <span style={{fontFamily:'Inter',fontSize:'16px',fontStyle:'normal',color:'#677079'}} ><span style={{color:'#ea5932'}} >{item.span}</span>{item.kg}</span> */}
                        <div className='bg-text' style={{fontFamily:"Poppins",fontSize:"16px",fontStyle:'normal',color:'#677079'}} >
                      
                      <input type='number' className='border-0' style={{width:'50px',color:'#EA5932'}} placeholder={item.rate?.length>0?item.rate:"0"} onChange={(e)=>{setname2(e.target.value)}} /> /{item.si_unit}</div>
                    </div>

                    <div className='m-auto mb-3 mt-2' >
                      <button className='w-100 px-5 m-auto py-1 bg-transparent' 
                      onClick={()=>{AddRetails(item.id)}} style={{color:'#FF8604',border:'1px solid #FF8604',borderRadius:'10px',
                      fontFamily:'Poppins',fontStyle:'normal',fontSize:'14px'}} >Save</button>
                    </div>

                </div> 
                </div>
                )
                })
               }
            </div>

            </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <div  style={{height:'60vh',overflowY:"scroll"}} >
    <div className='row' >
    {
                card1?.map((item,res)=>{
                  return(
      <div onClick={()=>{setValue(2);setDistrict(item.id);setSiunit(item.si_unit)}} key={res} className='col-lg-3 mt-3'>  
                <div className='card myCard' onClick={()=>{RetailsDetials(item.id)}}>
                    <p className=' px-3 mt-3' h2style={{color:'#000',fontFamily:'Inter',fontSize:'16px'}} >{item.name}</p>
                </div> 
            </div>
            )
                })
               }
    </div>
    </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <div style={{height:'58vh',overflowY:'scroll'}} >
      <div className='row' >
    {
                card2.map((item,res)=>{
                  return(
      <div className='col-lg-3 mt-3'> 
            {/* <NavLink to="/RetailRatesElectronics/LiveRates" style={{textDecoration:'none'}} > */}
                <div className='card myCard'>
                    <div className='d-flex justify-content-between align-items-center px-3' >

                      <p className='mt-3' style={{color:'#000',fontFamily:'Inter',fontSize:'16px'}} >{item.name}</p>
                      <div className='bg-text' style={{fontFamily:"Poppins",fontSize:"16px",fontStyle:'normal',color:'#677079'}} >
                      
                      <input type='number' className='border-0' style={{width:'45px',color:'#EA5932'}} placeholder={item.rate?.length>0?item.rate:item.rate} onChange={(e)=>{setname(e.target.value)}} /> /{Siunit}</div>

                    </div>

                    <div className='m-auto mb-3' >
                      <button className='w-100 px-5 m-auto py-1 bg-transparent' style={{color:'#FF8604',border:'1px solid #FF8604',borderRadius:'10px',fontFamily:'Poppins',fontStyle:'normal',fontSize:'14px'}} onClick={()=>{AddDisVal(item.id)}}>Save</button>
                    </div>
                </div>
            {/* </NavLink> */}
            </div>
            )
                })
               }
    </div>
    </div>
      </TabPanel>
    </Box>
                </div>
            </div>

            </div>

        </div>
       </div>


               
        {/* modal started  */}


<div class="modal fade" id="staticBackdropDisButtton2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h1 style={{fontFamily:'Inter',fontStyle:'normal',fontSize:'32px',color:'#000'}} >Add District</h1>
        <p className='mt-3' style={{fontFamily:'Inter',fontSize:'20px',fontStyle:'normal',color:'#000'}} >Name</p>
        <div className='' >
            <input type='text' className='px-3 py-2' placeholder='Name... ' style={{width:'100%',background:'#FAFAFA',borderRadius:'5px',border:'none'}} onChange={(e)=>{setname1(e.target.value)}} />
        </div>
        <div className='col-5 m-auto mt-5' >
            <button data-bs-dismiss="modal" aria-label="Close" className='py-2' style={{backgroundColor:'#040404',borderRadius:'5px',color:'#fff',fontFamily:'Roboto',fontStyle:'normal',fontSize:'14px',width:'100%',border:'none'}} onClick={()=>{AddDist()}}>Confirm</button>

        </div>
      </div>
      
    </div>
  </div>
</div>
        {/* modal end  */}


    </>
  )
}

export default RetElectronics

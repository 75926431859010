import {React, useState} from 'react'
import img1 from "../assets/img1.png"
import img2 from "../assets/13 1.png"
import {MdOutlineEmail} from "react-icons/md";
import { NavLink } from 'react-router-dom';


const ForgotPassword = () => {

    const [name, setname] = useState("");
    console.log(name);

  return (
    <>
    <div style={{background:'#000'}} >
       <div className='container m-0 p-0' >
            <div className='row align-items-center' >
                    <div className='col-lg-6 col-md-6' >
                        <img src={img1} alt='nn' style={{width:'100%',height:'100vh'}} />
                    </div>

                    <div className='col-lg-6 col-md-6' >
                        <div className='d-flex flex-column justify-content-between' >

                            <div className='mb-5' >
                                <img src={img2} alt='nn' style={{width:'179.2px'}} />
                            </div>  

                            <div>
                                <h3 style={{fontFamily:"Montserrat",fontStyle:"normal",fontSize:"36px",color:'#E3E3E3'}} >Forgot Password?</h3>

                                <p style={{color:"#BDBDBD",fontFamily:"Montserrat",fontStyle:'normal',fontSize:"20px"}} >No worries, We will send you reset instructions.</p>
                            </div>

                            <div>
                                    <label for='userEmail' style={{fontFamily:'Montserrat',fontStyle:'normal',fontSize:'14px',color:'#E3E3E3'}} >Enter your E-mail here</label>
                                    <div class="input-group mb-3" style={{border:'1px solid #BFBFBF',borderRadius:'5px',fontFamily:'Montserrat',fontStyle:'normal',fontSize:'14px',}} >
                                      <span class="input-group-text border-0" id="basic-addon1" style={{background:'#000',color:'#bdbdbd'}} ><MdOutlineEmail/></span>
                                      <input id='userEmail' style={{background:'#000',color:'#bdbdbd'}} type="email" class="form-control border-0 emPlace" placeholder="NarayanMurthy@gmail.com" aria-label="Username" aria-describedby="basic-addon1" onChange={(e)=>{setname(e.target.value)}} />
                                    </div> 
                            </div>

                            <div className='mt-5' >
                                <NavLink to='/ForgotPasswordOtp'>
                                    <button className='w-100 py-3' style={{color:'#FF8604',fontFamily:'Montserrat',fontStyle:'normal',fontSize:'14px',border:'1px solid #FF8604',borderRadius:'5px',background:'#000'}} >Reset Password</button>
                                </NavLink>
                            </div>

                        </div>

                    </div>
            </div>
       </div> 
    </div>
    </>
  )
}

export default ForgotPassword


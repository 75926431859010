import {React, useState,useEffect} from 'react'
import img1 from "../assets/img1.png"
import img2 from "../assets/13 1.png"
import {MdOutlineEmail} from "react-icons/md";
import {AiFillEye} from "react-icons/ai";
import {BiSolidLockAlt} from "react-icons/bi";
import {MdOutlineWavingHand} from "react-icons/md";
import { NavLink, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
 
  const Navigate = useNavigate(); 

  const [name, setname] = useState("");
  console.log(name);

  const [name1, setname1] = useState("");
  console.log(name1);
  const [Loading, setloading] = useState(false);
  
  

   const lOGIN = async()=>{
    setloading(true);
     try {
      var formdata = new FormData();
      formdata.append("email", name);
      formdata.append("password", name1);

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch("https://www.scraponwheels.com/api/admin/login", requestOptions)
        .then(response => response.json())
        .then(result => {console.log(result);
          if(result.status=="001"){
            Navigate("/Home");
            localStorage.setItem("token",result.token);
            toast(result.message);
          } 
          else{
            toast(result.message);
          }
        })
        .catch(error => console.log('error', error))
        .finally(()=>{setloading(false)})
        ;
     } catch (error) {
      
     }
   }


     
   useEffect(() => {
       if(!localStorage.getItem("token")){
       // toast("Pls Login")
       }
       else{
         Navigate("/Home");
       }
   }, []);
   


  return (
    <>
    <ToastContainer />
        <div style={{background:'#000'}} >
            <div className='container m-0 p-0' >
                <div className='row align-items-center' >

                    <div className='col-lg-6 col-md-6' >
                        <img src={img1} alt='nn' style={{width:'100%',height:'100vh'}} />
                    </div>

                    <div className='col-lg-6 col-md-6' >
                        <div className='d-flex flex-column justify-content-between' >
                              <div className='mb-5' >
                                <img src={img2} alt='nn' style={{width:'179.2px'}} />
                              </div>          

                              <div className='mt-3' >
                                <h1 style={{fontFamily:'Montserrat',fontSize:'36px',fontStyle:'normal',color:'#E3E3E3'}} >Hey, Hello <MdOutlineWavingHand/></h1>
                                <p style={{fontFamily:'Montserrat',fontSize:'20px',fontStyle:'normal',color:'#E3E3E3'}} >Enter the information you entered while registering</p>
                              </div>

                              <div className='' >
                                <h2 style={{fontFamily:'Montserrat',fontStyle:'normal',fontSize:'26px',color:'#E3E3E3'}} >Login</h2>
 
                                  <div>
                                    <label for='userEmail' style={{fontFamily:'Montserrat',fontStyle:'normal',fontSize:'14px',color:'#E3E3E3'}} >Email address</label>
                                    <div class="input-group mb-3" style={{border:'1px solid #BFBFBF',borderRadius:'5px',fontFamily:'Montserrat',fontStyle:'normal',fontSize:'14px',}} >
                                      <span class="input-group-text border-0" id="basic-addon1" style={{background:'#000',color:'#bdbdbd'}} ><MdOutlineEmail/></span>
                                      <input id='userEmail' style={{background:'#000',color:'#bdbdbd'}} type="email" class="form-control border-0 emPlace" placeholder="Pls Enter Email Address" aria-label="Username" aria-describedby="basic-addon1" onChange={(e)=>{setname(e.target.value)}} />
                                    </div>
                                  </div>

                                  <div>
                                    <label for='userEmail' style={{fontFamily:'Montserrat',fontStyle:'normal',fontSize:'14px',color:'#E3E3E3'}} >Password</label>
                                    <div class="input-group mb-3" style={{border:'1px solid #BFBFBF',borderRadius:'5px',fontFamily:'Montserrat',fontStyle:'normal',fontSize:'14px',}} >
                                      <span class="input-group-text border-0" id="basic-addon1" style={{background:'#000',color:'#bdbdbd'}} ><BiSolidLockAlt/></span>
                                      <input id='userEmail' style={{background:'#000',color:'#bdbdbd'}} type="password" class="form-control border-0 emPlace" placeholder="Enter Password" aria-label="Username" aria-describedby="basic-addon1" onChange={(e)=>{setname1(e.target.value)}} />
                                      <span class="input-group-text border-0" style={{background:'#000',color:'#bdbdbd',cursor:'pointer'}}><AiFillEye/></span>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between' style={{fontFamily:'Montserrat',fontStyle:'normal',fontSize:'14px',color:'#CDCDCD'}} >
                                        <div className='d-flex align-items-center column-gap-1' >
                                            <input type='checkbox' />
                                            <span>Remember me</span>
                                        </div>
                                        <div>
                                        
                                        </div>
                                      
                                    </div>

                                    <div className='mt-5' >
                                   
                                        <button className='w-100 py-3' onClick={()=>{lOGIN()}} style={{color:'#FF8604',fontFamily:'Montserrat',fontStyle:'normal',fontSize:'14px',border:'1px solid #FF8604',borderRadius:'5px',background:'#000'}} >{Loading==false?"Login":"Loading......"}</button>
                                   
                                    </div>
                                  </div>
                              
                              </div> 
                        </div>
                    </div>
                    
                </div>

            </div>
        </div>
    </>
  )
}

export default Login






// loadder 

// const [loading,setloading] = useState();


// // Api 
// const Login = () =>{
//   setloading(true);
// }

// .finally(()=>{setloading(false)})


// <button>{loading==false?"Login":"Loading...."}</button>
import React,{useEffect} from 'react';
import Sidebar from '../Sidebar';
import RatesData from './RatesData';
import { useNavigate } from 'react-router-dom';

const Rates = () => {
  let Navigate = useNavigate();
  useEffect(() => {
    if(!localStorage.getItem("token")){
        Navigate("/");
    }
    else{
      
    }
}, []);
  return (
    <>
        <div className='container-fluid' >
          <div className='row'>
            <div className='col-lg-2 sidebar' >
              <Sidebar/>
            </div>

            <div className='col-lg-10' >
              <RatesData/>
            </div>
          </div>
        </div>
    </>
  )
}

export default Rates

import React from 'react'
import img1 from "../assets/img1.png"
import img2 from "../assets/13 1.png"
import {NavLink} from "react-router-dom"

const SignUpVerifySucc = () => {
  return (
    <>
        <div style={{background:'#000'}} >
            <div className='container m-0 p-0' >
                <div className='row align-items-center' >

                    <div className='col-lg-6 col-md-6' >
                        <img src={img1} alt='nn' style={{width:'100%',height:'100vh'}} />
                    </div>

                    <div className='col-lg-6 col-md-6' >
                        <div className='d-flex flex-column justify-content-between' >
                              <div className='mb-3' >
                                <img src={img2} alt='nn' style={{width:'179.2px'}} />
                              </div>          

                              <div className='' >
                                <h1 style={{fontFamily:'Montserrat',fontSize:'36px',fontStyle:'normal',color:'#E3E3E3'}} >OTP Verification</h1>
                              </div>

                              <div className='mt-5 m-auto' >
                                    <p style={{fontFamily:'Montserrat',fontSize:'26px',fontStyle:'normal',color:'#E3E3E3',textAlign:'center'}} >OTP Verification has been successful !</p>
                              </div>

                              <div className='mt-5' >
                                      <NavLink to='/Login' >
                                        <button className='w-100 py-3' style={{color:'#FF8604',fontFamily:'Montserrat',fontStyle:'normal',fontSize:'14px',border:'1px solid #FF8604',borderRadius:'5px',background:'#000'}} >Login</button>
                                      </NavLink>
                                    </div>
                        </div>
                    </div>
                    
                </div>

            </div>
    </div>
    </>
  )
}

export default SignUpVerifySucc

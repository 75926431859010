import React, { useEffect, useState } from 'react';
import {NavLink, useNavigate} from "react-router-dom"
import {CiCalendar} from "react-icons/ci";
import {HiUser} from "react-icons/hi";
import "./VendorsData.css";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {AiOutlineSearch} from "react-icons/ai";
import {BiMenuAltLeft} from "react-icons/bi"
import {FaUser} from "react-icons/fa"
import {BsTelephoneFill} from "react-icons/bs"
import {IoGitMerge, IoLocationSharp} from "react-icons/io5"
import {MdEmail} from "react-icons/md"
import lotti from '../assets/animation_llaix5r2.json';
import Lottie from "lottie-react";
import { ToastContainer, toast } from 'react-toastify';



function TabPanel(props) {
  const { children, value, index, ...other } = props;





  return (




    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const VendorsData = () => {

  const [name1, setname1] = useState("");
  console.log(name1);

  const [name2, setname2] = useState("");
  console.log(name2);

  const [name3, setname3] = useState("");
  console.log(name3);

  const [Vendor, setVendor] = useState();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

   

  let navigate = useNavigate();


  const [card, setCard] = useState([]);
  const [card1, setCard1] = useState([]);
  const [card2, setCard2] = useState([]);
  const [card3, setCard3] = useState([]);
  const [Request, setRequest] = useState([]);


  
    const Apicollectors = async() =>{
      try {
        var formdata = new FormData();
        formdata.append("token", localStorage.getItem("token"));
        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };
        
        fetch("https://www.scraponwheels.com/api/admin/vendor_collectors", requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result);
          if(result.status=="001"){
            setCard(result.message);
          }
          else if(result.status=='009'){ 
            navigate("/");
            localStorage.removeItem("token")
          }
        })
          .catch(error => console.log('error', error));
        
      } catch (error) {
        console.log(error);
      }
      
    }


    const Apiwholesalers = async() =>{
      try {
        var formdata = new FormData();
        formdata.append("token", localStorage.getItem("token"));
        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };
        
        fetch("https://www.scraponwheels.com/api/admin/vendor_wholesalers", requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result);
          if(result.status=="001"){
            setCard1(result.message);
          }
          else if(result.status=='009'){ 
            navigate("/");
            localStorage.removeItem("token")

          }
        })
          .catch(error => console.log('error', error));
        
      } catch (error) {
        console.log(error);
      }
      
    }


    const Apimediators = async() =>{
      try {
        var formdata = new FormData();
        formdata.append("token", localStorage.getItem("token"));
        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };
        
        fetch("https://www.scraponwheels.com/api/admin/vendor_mediators", requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result);
          if(result.status=="001"){
            setCard2(result.message);
          }
          else if(result.status=='009'){ 
            navigate("/");
            localStorage.removeItem("token")
          }
        })
          .catch(error => console.log('error', error));
        
      } catch (error) {
        console.log(error);
      }
      
    }


    const Apiviewdetails = async(id) =>{
         setVendor(id);
      try {
        var formdata = new FormData();
        formdata.append("token", localStorage.getItem("token"));
        formdata.append("id", id);
        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };
        
        fetch("https://www.scraponwheels.com/api/admin/viewdetails", requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result);
          if(result.status=="001"){
            setCard3(result.message);
          }
          else if(result.status=='009'){ 
            navigate("/");
          }
          else{
            toast(result.message);
          }
        
        })
          .catch(error => console.log('error', error));
        
      } catch (error) {
        console.log(error);
      }
     
    }


    const Search1 = async(value)=>{
      try {
        var formdata = new FormData();
        formdata.append("token", localStorage.getItem("token"));
        formdata.append("search", value);

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };

        fetch("https://www.scraponwheels.com/api/admin/vendor_search_collector", requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result);
            if(result.status=="001"){
              setCard(result.message);
            }
            else{
              toast(result.message);
            }
          })
          .catch(error => console.log('error', error));
      } catch (error) {
        
      }
    }

    const Search2 = async(value)=>{
      try {
        var formdata = new FormData();
        formdata.append("token", localStorage.getItem("token"));
        formdata.append("search", value);

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };

        fetch("https://www.scraponwheels.com/api/admin/vendor_search_wholesaler", requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result);
            if(result.status=="001"){
              setCard1(result.message);
            }
            else{
              toast(result.message);
            }
          })
          .catch(error => console.log('error', error));
      } catch (error) {
        
      }
    }

    const Search3 = async(value)=>{
      try {
        var formdata = new FormData();
        formdata.append("token", localStorage.getItem("token"));
        formdata.append("search", value);

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };

        fetch("https://www.scraponwheels.com/api/admin/vendor_search_mediator", requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result);
            if(result.status=="001"){
              setCard2(result.message);
            }
            else{
              toast(result.message);
            }
          })
          .catch(error => console.log('error', error));
      } catch (error) {
        
      }
    }


    const ChangeStatus = (status)=>{
        if(status=="Active"){
          var formdata = new FormData();
          formdata.append("token", localStorage.getItem("token"));
          formdata.append("vendor_id", Vendor);

          var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
          };

          fetch("https://www.scraponwheels.com/api/admin/vendor_status_active", requestOptions)
            .then(response => response.json())
            .then(result => {console.log(result);
              if(result.status=="001"){
                toast(result.message);
             }
             else{
               toast(result.message);
             }
            })
            .catch(error => console.log('error', error));
        
        }
        else if(status=="Disable"){
          var formdata = new FormData();
          formdata.append("token", localStorage.getItem("token"));
          formdata.append("vendor_id", Vendor);
          
          var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
          };
          
          fetch("https://www.scraponwheels.com/api/admin/vendor_status_disable", requestOptions)
            .then(response => response.json())
            .then(result => {console.log(result);
              if(result.status=="001"){
                 toast(result.message);
              }
              else{
                toast(result.message);
              }
            })
            .catch(error => console.log('error', error));
          }
        else{

        }
    }


    const Apidate =()=>{
      try {
        var formdata = new FormData();
        formdata.append("token", localStorage.getItem("token"));
        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };
        
        fetch("https://www.scraponwheels.com/api/admin/vendor_request_show", requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result);
          if(result.status=="001"){
            setRequest(result.message);
          }})
          .catch(error => console.log('error', error));
        
      } catch (error) {
        console.log(error);
      }
      
    }




  useEffect (()=>{
    Apicollectors();
    Apidate();
  },[])
  useEffect (()=>{
    Apiwholesalers();
  
  },[])

  useEffect (()=>{
    Apimediators();
  
  },[]);

  useEffect(() => {
    if(!localStorage.getItem("token")){
        navigate("/");
    }
    else{
      
    }
  }, []);
 
  return (
    <>
    <ToastContainer/>
    {/* {top icons started} */}
      <div className='d-flex justify-content-end align-items-center mt-4' >
        <CiCalendar className='calenderIcon p-2 me-3' />
        <HiUser className='userIcon p-2' />
      </div>   
      {/* {top icons end} */}


{/* vendors heading started  */}
      <div className='d-flex align-items-center justify-content-between' >
        <h1 className='vendorHeading' >Vendors </h1>
        <div>
        <NavLink to="/Request">
          
            <button className='vendorButton1 py-1 px-2 me-2 position-relative'>Requests
            <span class={`position-absolute top-0 start-100 translate-middle p-2 ${Request.length>0?"bg-danger":""} border border-light rounded-circle`}>
              <span class="visually-hidden">New alerts</span>
            </span></button>
        </NavLink>
            <button className='vendorButton2 py-1 px-2' >+ Add Vendor</button>
        </div>
      </div>
      {/* vendors heading end */}

      <div className='container-fluid' >
        <div className='row' >
          <div className='col-lg-12' >
           <Box sx={{ width: '100%' }}>
             <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
               <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                 <Tab className='tabNavs' label="Scrap collectors" {...a11yProps(0)} onClick={()=>Apicollectors()}/>
                 <Tab className='tabNavs' label="Scrap Wholesalers" {...a11yProps(1)} onClick={()=>Apiwholesalers()}/>
                 <Tab className='tabNavs' label="Scrap Mediators" {...a11yProps(2)} onClick={()=>Apimediators()}/>
               </Tabs>
             </Box>
             <TabPanel value={value} index={0}>
               <div className='row d-flex align-items-center'>
                  <div className='col-lg-3 g-0' >
                  <div class="input-group searchField">
                      <span class="input-group-text border-0 bg-transparent" id="basic-addon1"><AiOutlineSearch/></span>
                      <input type="text" class="form-control border-0 bg-transparent" placeholder="Search..." aria-label="Username" aria-describedby="basic-addon1" 
                      onChange={(e)=>{setname1(e.target.value);Search1(e.target.value)}} />
                      
                  </div>
                  </div>
                    <div className='col-lg-3' >
                      <button className='aToz-Btn border-0 px-2 py-1' > <BiMenuAltLeft style={{fontSize:'1.5rem'}} />A-Z</button>
                    </div>
               </div>
               <div className='scroll mt-2' >
               <div className='row mt-3 m-0' >
               {
                card?.length>0?
                card.map((item,res)=>{
                  return(
                    <div key={res} className='col-lg-3 mb-3' >
              <div className='card h-100'>
                <div className='card-body'>

                  <div className='row '> 
                  <div className='col-1' >
                    <FaUser/>
                  </div>
                  <div className='col-10 ms-2'>
                    <span className='cardText' >{item.name}</span>
                  </div> 
                  </div>


                  <div className='row mt-3'> 
                  <div className='col-1' >
                    <BsTelephoneFill/>
                  </div>
                  <div className='col-10 ms-2'>
                    <span className='cardText' > {item.mobile}</span>
                  </div> 
                  </div>


                  <div className='row mt-3'>
                    <div className='col-1'>
                    <IoLocationSharp style={{fontSize:'18px'}}/>
                    </div>
                    <div className='col-10 ms-2'>
                    <span className='cardText' > {item.shop_address} </span>
                    </div>
                  </div>

                  <div className='row mt-5' >
                    <div className='col-10 m-auto'>
                      <button  data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" className=' cardButton py-2 w-75 position-absolute bottom-0  start-50 translate-middle' onClick={()=>{Apiviewdetails(item.id)}}>View Details</button>
                    </div>

                  </div>


                </div>
              </div>
          </div>
                  )
                }):<div>
                <div className='d-flex ' style={{justifyContent:"center",alignItems:"center"}}>
       <Lottie animationData={lotti} style={{width:"50%",height:"15rem",marginLeft:"0rem",}}/>
                  </div>
                {/* <h6 style={{color:"red"}}>No Data Found</h6> */}
                </div>
               }
          
        </div>
        </div>
             </TabPanel>
             <TabPanel value={value} index={1}>
             <div className='row d-flex align-items-center'>
                  <div className='col-lg-3 g-0' >
                  <div class="input-group searchField">
                      <span class="input-group-text border-0 bg-transparent" id="basic-addon1"><AiOutlineSearch/></span>
                      <input type="text" class="form-control border-0 bg-transparent" placeholder="Search..." aria-label="Username" aria-describedby="basic-addon1" onChange={(e)=>{setname2(e.target.value);Search2(e.target.value)}} />
                  </div>
                  </div>
                    <div className='col-lg-3' >
                      <button className='aToz-Btn border-0 px-2 py-1' > <BiMenuAltLeft style={{fontSize:'1.5rem'}} />A-Z</button>
                    </div>
               </div>
               <div className='scroll mt-2' >
               <div className='row mt-3 m-0' >
               {
                card?.length>0?
                card1.map((item,res)=>{
                  return(
                    <div key={res} className='col-lg-3 mb-3' >
              <div className='card h-100'>
                <div className='card-body'>

                  <div className='row '> 
                  <div className='col-1' >
                    <FaUser/>
                  </div>
                  <div className='col-10 ms-2'>
                    <span className='cardText' >{item.name}</span>
                  </div> 
                  </div>


                  <div className='row mt-3'> 
                  <div className='col-1' >
                    <BsTelephoneFill/>
                  </div>
                  <div className='col-10 ms-2'>
                    <span className='cardText' > {item.mobile}</span>
                  </div> 
                  </div>


                  <div className='row mt-3'>
                    <div className='col-1'>
                    <IoLocationSharp style={{fontSize:'18px'}}/>
                    </div>
                    <div className='col-10 ms-2'>
                    <span className='cardText' > {item.shop_address} </span>
                    </div>
                  </div>

                  <div className='row mt-5' >
                    <div className='col-10 m-auto'>
                      <button  data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" className=' cardButton py-2 w-75 position-absolute bottom-0  start-50 translate-middle' onClick={()=>{Apiviewdetails(item.id)}}>View Details</button>
                    </div>

                  </div>


                </div>
              </div>
          </div>
                  )
                }):<div>
                <div className='d-flex ' style={{justifyContent:"center",alignItems:"center"}}>
       <Lottie animationData={lotti} style={{width:"50%",height:"15rem",marginLeft:"0rem",}}/>
                  </div>
                {/* <h6 style={{color:"red"}}>No Data Found</h6> */}
                </div>
               }
          
        </div>
        </div>
             </TabPanel>
             <TabPanel value={value} index={2}>
             <div className='row d-flex align-items-center'>
                  <div className='col-lg-3 g-0' >
                  <div class="input-group searchField">
                      <span class="input-group-text border-0 bg-transparent" id="basic-addon1"><AiOutlineSearch/></span>
                      <input type="text" class="form-control border-0 bg-transparent" placeholder="Search..." aria-label="Username" aria-describedby="basic-addon1" onChange={(e)=>{setname3(e.target.value);Search3(e.target.value)}} />
                  </div>
                  </div>
                    <div className='col-lg-3' >
                      <button className='aToz-Btn border-0 px-2 py-1' > <BiMenuAltLeft style={{fontSize:'1.5rem'}} />A-Z</button>
                    </div>
               </div>
               <div className='scroll mt-2' >
               <div className='row mt-3 m-0' >
               {
                card?.length>0?
                card2?.map((item,res)=>{
                  return(
                    <div key={res} className='col-lg-3 mb-3' >
              <div className='card h-100'>
                <div className='card-body'>

                  <div className='row '> 
                  <div className='col-1' >
                    <FaUser/>
                  </div>
                  <div className='col-10 ms-2'>
                    <span className='cardText' >{item.name}</span>
                  </div> 
                  </div>


                  <div className='row mt-3'> 
                  <div className='col-1' >
                    <BsTelephoneFill/>
                  </div>
                  <div className='col-10 ms-2'>
                    <span className='cardText' > {item.mobile}</span>
                  </div> 
                  </div>


                  <div className='row mt-3'>
                    <div className='col-1'>
                    <IoLocationSharp style={{fontSize:'18px'}}/>
                    </div>
                    <div className='col-10 ms-2'>
                    <span className='cardText' > {item.shop_address} </span>
                    </div>
                  </div>

                  <div className='row mt-5' >
                    <div className='col-10 m-auto'>
                      <button  data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" className=' cardButton py-2 w-75 position-absolute bottom-0  start-50 translate-middle' onClick={()=>{Apiviewdetails(item.id)}}>View Details</button>
                    </div>

                  </div>


                </div>
              </div>
          </div>
                  )
                }):<div>
                <div className='d-flex ' style={{justifyContent:"center",alignItems:"center"}}>
       <Lottie animationData={lotti} style={{width:"50%",height:"15rem",marginLeft:"0rem",}}/>
                  </div>
                {/* <h6 style={{color:"red"}}>No Data Found</h6> */}
                </div>
               }
          
        </div>
        </div>
             </TabPanel>
           </Box>
          </div>
        </div>


       



       {/* offcanvas started  */}
       <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{width:'50%'}} >
  <div class="offcanvas-header">
    {/* <h5 class="offcanvas-title" id="offcanvasRightLabel">Offcanvas right</h5> */}
    <button type="button" class="btn-close offcanvasCross" data-bs-dismiss="offcanvas" aria-label="Close" ></button>
  </div>
  <div class="offcanvas-body">
    <div className='container' >
      <div className='row' >
      {
        card3.map((res,key)=>{
              return(
        <div className='col-lg-12' key={key}  >
           
           

          <div className='col-lg-8 ' >
            <h1 className='offcanvasH1' >Personal Details</h1>
            <div className='d-flex align-items-center mt-3 ' >
              <FaUser className='me-4 offcanvasText' />
              <span className='offcanvasText' >{res.name}</span>
            </div>
            <div className='d-flex align-items-center mt-3 ' >
              <MdEmail className='me-4 offcanvasText' />
              <span className='offcanvasText ' >{res.email}</span>
            </div>
            <div className='d-flex align-items-center mt-3 ' >
              <BsTelephoneFill className='me-4 offcanvasText' style={{transform:'rotate(280deg)'}} />
              <span className='offcanvasText' >{res.mobile}</span>
            </div>
            </div>
            <div>
              <h4 className='offcanvasH4' style={{marginTop:20}}>Shop Details</h4>
              <span style={{marginTop:20,fontWeight:600}}>Shop Name :</span>
              <h6 className='offcanvasShopDetails mt-1' >{res.shop_name}</h6>

              <div className='mt-3' >
              <span style={{marginTop:20,fontWeight:600}}>Shop Address :</span>
                <h6 className='shopNameHeading' >{res.shop_address}</h6>
                <h3 className='shopName' >{res.Kname}</h3>
              </div>
              <div className='mt-4 d-flex justify-content-between' >
                <div>
                <span style={{marginTop:20,fontWeight:600}}>Udhyam Aadhar No :</span>
                  <h6 className='aadharText' >{res.aadhar}</h6>
                  
                </div>
                <div>
                <span style={{marginTop:20,fontWeight:600}}>Gst No :</span>
                  <h6 className='gstText' >{res.gst}</h6>
                </div>
              </div>

              <div className='mt-4' >
              <div style={{display:'flex',alignItems:'center'}}>
                <h5 style={{fontWeight:'400', }} >Status:</h5>
                <select className='ms-3 ' style={{borderRadius:'5px'}} onChange={(e)=>{ChangeStatus(e.target.value)}}>
                  <option value="Active" selected={res.status=="Active"?true:false}>Active</option>
                  <option value="Disable" selected={res.status=="Disable"?true:false}>Disable</option>
                </select>
                </div>
              </div>

            </div>



            <div className='mt-4'>
                  <h6 className='addressText'>Shop Photo</h6>
                  <img src={`https://www.scraponwheels.com/public/Profile/${res.shop_photo}`}  style={{width:'50%'}}/>
                </div>
  


        </div>
              )
            })
           }
 

      </div>


    </div>
  </div>
</div>
      </div>
    </>
  )
}

export default VendorsData;

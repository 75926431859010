import React,{useEffect,useState} from 'react';
import { HiUser } from 'react-icons/hi'
import { BsArrowRight } from 'react-icons/bs'
import Sidebar from '../Sidebar'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import "./ElectronicsPage.css"
import { ToastContainer, toast } from 'react-toastify';

const ElectronicsPage = () => {

  const {id,name123} = useParams();

  const [card, setCard] = useState([]);

  const [name, setname] = useState("");
  const [id1, setId1] = useState();
  //console.log(name)

  const [name1, setname1] = useState("");
  console.log(name1)


   let Navigate = useNavigate();
 
   const Api = async()=>{
    try {
      var formdata = new FormData();
      formdata.append("token", localStorage.getItem("token"));
      formdata.append("category_id", id);
      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch("https://www.scraponwheels.com/api/admin/subcategory", requestOptions)
        .then(response => response.json())
        .then(result => {console.log("fgg dbgfh",result)
      if(result.status=="001"){
          setCard(result.message)
      }})
        .catch(error => console.log('error', error));
    } catch (error) {
      console.log(error)
    }
   }

   const AddSub = ()=>{
    try {
      var formdata = new FormData();
      formdata.append("token", localStorage.getItem("token"));
      formdata.append("category_id", id);
      formdata.append("name", name);
      formdata.append("si_unit", name1);
      
      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };
      
      fetch("https://www.scraponwheels.com/api/admin/subcategory_add", requestOptions)
        .then(response => response.json())
        .then(result => {console.log(result);
          if(result.status=="001"){
             toast(result.message);
             Api();
          }
          else{
            toast(result.message);
          }
        })
        .catch(error => console.log('error', error));
    } catch (error) {
      
    }
   }


  const Delete = (id) => {

    var formdata = new FormData();
formdata.append("token", localStorage.getItem("token"));
formdata.append("id", id);

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("https://www.scraponwheels.com/api/admin/subcategory_disable", requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status=="001"){
      toast(result.message)
    }else{
      toast(result.message)
    }
  })
  .catch(error => console.log('error', error));








  }


  useEffect (()=>{
    //setCard(myData);
    Api();
  },[]);


  useEffect(() => {
    if(!localStorage.getItem("token")){
        Navigate("/");
    }
    else{
      
    }
  }, []);

  return (
   <>
   <ToastContainer/>
      <div className='container-fluid' >
          <div className='row'>
            <div className='col-lg-2 sidebar' >
              <Sidebar/>
            </div>

            <div className='col-lg-10' >
            <div className='d-flex justify-content-end align-items-center mt-4' >
              <HiUser className='userIcon p-2' />
              </div> 

              <div className='col-lg-12 mt-4' >
               <div className='d-flex justify-content-between align-items-center' >
               <NavLink to='/Categories' >
                  <IoIosArrowRoundBack className='backButton' />
               </NavLink>
                  <div>
                    <span data-bs-toggle="modal" data-bs-target="#staticBackdropMod" className='p-2 vendorButton2' >
                       + Add SubCategory</span>
                  </div>
               </div>

               <div>
                        <h1 className='mainHeading' >{name123}</h1>
                   </div>

              </div>

              <div className='container-fluid' >
                <div style={{height:'65vh',overflowY:'scroll'}} >
                <div className='row'>
                {
                card.map((item,res)=>{ 
                  return(
                <div key={res} className='col-3 mt-3' style={{cursor:'pointer'}} >
                <div class="card" style={{width:'100%'}}>
                    <div class="card-body">

                      <div className='row'>
                        <div className='col-9'>
                        <h5 class="card-title" style={{fontFamily:'Inter',fontStyle:'normal',fontSize:'24px',color:'#000'}} >
                          {item.name}</h5>
                        <p class="card-text" style={{fontFamily:'Inter',fontStyle:'normal',fontSize:'16px',color:'#000'}} >
                          <span style={{color:'#EA5932'}} >{item.rate}</span>/{item.si_unit}</p>
                        </div>

                        <div className='col-3 '>
                         <h3> <BsArrowRight/> </h3> 
                        </div>
                      </div>

                      <div>
                      <button className='mt-4' style={{backgroundColor:'#ffd8dc', color:'red', border:'none', 
                      borderRadius:'8px', padding:'4px 8px', width:'100%'}} data-bs-toggle="modal" data-bs-target="#exampleModal"
                      onClick={()=>{setId1(item.id)}}> Delete </button>

                      </div>
                         
                    </div>
                    </div>
                </div>
                )
                })
               }
               </div>
                </div>

              </div>
            </div>
          </div>
        </div>







        {/* modal  */}

<div class="modal fade" id="staticBackdropMod" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
 aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel" style={{fontFamily:'Inter',fontSize:'82px !important',
        color:'#272727'}}>Add Subcategory</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{color:'#333333'}}>

        </button>
      </div>
      <div class="modal-body">
      <h6 style={{fontFamily:'Inter',fontStyle:'normal',fontSize:'24px',color:'#292929'}} >Name </h6>

      <div className='p-3' >
            <input type='text' className='px-3 py-2' placeholder='Start Typing Category Name... ' 
            style={{width:'100%',background:'#FAFAFA',borderRadius:'5px',border:'none'}}
             onChange={(e)=>{setname(e.target.value)}} />
        </div>

      <h6 className='mt-3' style={{fontFamily:'Inter',fontStyle:'normal',fontSize:'24px',color:'#292929'}} >SI UNIT </h6>

      <div className='p-3 col-1' >
            <input type='text' className='px-3 py-2' placeholder='Kg' style={{background:'#FAFAFA',borderRadius:'5px',
            border:'none'}} onChange={(e)=>{setname1(e.target.value)}} />
        </div>

        <div className='col-5 m-auto mt-3' >
            <button className='py-2' data-bs-dismiss="modal" aria-label="Close" onClick={()=>{AddSub()}}
             style={{backgroundColor:'#040404',borderRadius:'5px',color:'#fff',fontFamily:'Roboto',fontStyle:'normal',
             fontSize:'14px',width:'100%',border:'none'}} > Confirm </button>

        </div>
      </div>
   
    </div>
  </div>
</div>



{/* delete modal start */} 
 
 
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{backdropFilter:'blur(8px)'}}>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header border-0">
        {/* <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" ></button> */}
      </div>
      <div class="modal-body">
         <h6 className='text-center mb-5'>Are you sure you want to delete this Subcategory ?</h6>
        <div className='mb-4' style={{display:'flex',justifyContent:'space-around'}}>
          <button data-bs-dismiss="modal" aria-label="Close"  style={{backgroundColor:'#ffc4c4', color:'red', padding:'5px 30px',borderRadius:'8px',border:'none'}} onClick={()=>{Delete(id1)}}>yes</button>
         
          <button   data-bs-dismiss="modal" aria-label="Close"  style={{backgroundColor:'#d2d2d2', color:'#000', padding:'5px 30px',borderRadius:'8px',border:'none'}}>Cancel</button>

        </div>
      </div>
      
    </div>
  </div>
</div>
{/* delete modal end */}
   </>
  )
}

export default ElectronicsPage

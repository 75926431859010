import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const AreaChart = () => {
  const [chartOptions, setChartOptions] = useState({
    chart: {
      id: 'area-chart',
    },
    xaxis: {
      categories: ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'],
    },
  });

  const [seriesData, setSeriesData] = useState([
    {
      name: 'Series A',
      data: [30, 40, 35, 50, 49],
    },
  ]);

  return (
    <ReactApexChart
      options={chartOptions}
      series={seriesData}
      type="area"
      height={300}
    />
  );
};

export default AreaChart;

import React, { useEffect,useState } from 'react';
import Sidebar from '../Sidebar';
import {CiCalendar} from "react-icons/ci";
import {HiUser} from "react-icons/hi";
import {IoIosArrowRoundBack} from "react-icons/io";
import {NavLink, useNavigate} from "react-router-dom";
import {IoMdCall} from "react-icons/io"
import {RxCross2} from "react-icons/rx"
import {BsCheckLg} from "react-icons/bs"
import "./RequestsButtonPage.css"
import {FaEnvelope, FaUser} from "react-icons/fa"
import {BsTelephoneFill} from "react-icons/bs"
// import {IoGitMerge, IoLocationSharp} from "react-icons/io5"
import {MdMail} from "react-icons/md"
import { Card } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import lotti from '../assets/animation_llaix5r2.json';
import Lottie from "lottie-react";

const RequestsButtonPage = () => {
  const [card, setCard] = useState([]);
  const [Data, setData] = useState([]);


   let Navigate = useNavigate();


const Apidate =()=>{
  try {
    var formdata = new FormData();
    formdata.append("token", localStorage.getItem("token"));
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    
    fetch("https://www.scraponwheels.com/api/admin/vendor_request_show", requestOptions)
      .then(response => response.json())
      .then(result => {console.log(result);
      if(result.status=="001"){
        setCard(result.message);
      }})
      .catch(error => console.log('error', error));
    
  } catch (error) {
    console.log(error);
  }
  
}

 const ViewDetails = async(token)=>{
  try {
    var formdata = new FormData();
    formdata.append("token", localStorage.getItem("token"));
    formdata.append("vendor_id", token);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://www.scraponwheels.com/api/admin/vendor_request_show_details", requestOptions)
      .then(response => response.json())
      .then(result => {console.log(result);
        setData(result.message);
      })
      .catch(error => console.log('error', error));
  } catch (error) {
    
  }
 }
   

  const Approve = async(token)=>{
    var formdata = new FormData();
    formdata.append("token", localStorage.getItem("token"));
    formdata.append("vendor_id", token);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://www.scraponwheels.com/api/admin/vendor_approve", requestOptions)
      .then(response => response.json())
      .then(result => {console.log(result);
      if(result.status=="001"){
         toast(result.message);
         Apidate();
      }
      else{
      toast(result.message);
      }})
      .catch(error => console.log('error', error));
  }


   const Reject = (token)=>{
    var formdata = new FormData();
    formdata.append("token", localStorage.getItem("token"));
    formdata.append("vendor_id", token);
    
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    
    fetch("https://www.scraponwheels.com/api/admin/vendor_reject", requestOptions)
      .then(response => response.json())
      .then(result => {console.log(result)
      if(result.status=="001"){
       toast(result.message);
       Apidate();
      }
    else{
      toast(result.message);
    }})
      .catch(error => console.log('error', error));
   }


useEffect (()=>{
  Apidate();
},[])

useEffect(() => {
  if(!localStorage.getItem("token")){
      Navigate("/");
  }
  else{
    
  }
}, []);


  return (
   <>
   <ToastContainer/>
          <div className='container-fluid' >
          <div className='row'>
            <div className='col-lg-2 sidebar' >
              <Sidebar/>
            </div>

            <div className='col-lg-10' >
                 <div className='d-flex justify-content-end align-items-center mt-4' >
                    <CiCalendar className='calenderIcon p-2 me-3' />
                    <HiUser className='userIcon p-2' />
                </div>  

                <div className='col-lg-12 mt-3' >
                <NavLink to="/" >
                    <IoIosArrowRoundBack className='backButton' />
                </NavLink>
               
                   <div>
                        <h1 className='mainHeading' >Requests</h1>
                   </div>
                   <hr style={{border:' 1px solid #eaeaea',}} />
                </div>

              

                <div className='col-lg-12 mt-2' >
              

                  <div >
                    <div className='grid py-2' >
                      <div>S.No</div>
                      <div>Name</div>
                      <div>Date</div>
                      <div>Contact information</div>
                      <div>Details</div>
                      <div>Action</div>
                      <div>Type</div>
                    </div>

                    <div style={{height:'60vh',overflowY:'scroll'}}>
                    {
                      card?.length>0?
                      card?.map((res,key)=>{
                        return(
                      <div className='grid1 py-2 mt-2' key={key}>
                      <div>{key+1}</div>
                      <div>{res.name}</div>
                      <div>{res.date}</div>
                      <div><IoMdCall style={{color:'#40997E',}} />{res.contact}</div>
                      <div> <span data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight1" aria-controls="offcanvasRight" className='p-2'  style={{color:'#FF8604',border:'0.797px solid #FF8604',borderRadius:'6.234px',cursor:'pointer'}} onClick={()=>{ViewDetails(res.id)}}> View Details</span></div>
                      {/* <div><RxCross2 style={{color:'#E22626',border:'1px solid #E22626',background:'#fff',borderRadius:'50px',fontSize:'1.5rem',cursor:'pointer'}} /><BsCheckLg className='ms-2' style={{color:'#4BE226',border:'1px solid #4BE226',background:'#fff',borderRadius:'50px',fontSize:'1.5rem',cursor:"pointer"}} /></div> */}

                      <div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="82" height="25" viewBox="0 0 82 37" fill="none">
                          <circle cx="18.5" cy="18.5" r="18.0978" fill="white" stroke="#E22626" stroke-width="0.804348" style={{cursor:"pointer",}} onClick={()=>{Reject(res.id);console.log("hello")}}/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M27.7863 10.9786C27.9022 10.8628 27.9941 10.7254 28.0569 10.574C28.1197 10.4227 28.1521 10.2605 28.1522 10.0967C28.1523 9.93287 28.1201 9.77063 28.0575 9.61924C27.9949 9.46785 27.9031 9.33026 27.7873 9.21435C27.6716 9.09844 27.5341 9.00646 27.3828 8.94368C27.2315 8.88089 27.0693 8.84852 26.9054 8.84842C26.7416 8.84832 26.5794 8.88049 26.428 8.94309C26.2766 9.00569 26.139 9.0975 26.0231 9.21327L18.4999 16.7365L10.9788 9.21327C10.7447 8.97917 10.4272 8.84766 10.0961 8.84766C9.76505 8.84766 9.44755 8.97917 9.21345 9.21327C8.97935 9.44736 8.84784 9.76487 8.84784 10.0959C8.84784 10.427 8.97935 10.7445 9.21345 10.9786L16.7367 18.4997L9.21345 26.0207C9.09754 26.1367 9.00559 26.2743 8.94286 26.4257C8.88013 26.5772 8.84784 26.7395 8.84784 26.9034C8.84784 27.0673 8.88013 27.2297 8.94286 27.3811C9.00559 27.5325 9.09754 27.6702 9.21345 27.7861C9.44755 28.0202 9.76505 28.1517 10.0961 28.1517C10.26 28.1517 10.4224 28.1194 10.5738 28.0567C10.7253 27.9939 10.8629 27.902 10.9788 27.7861L18.4999 20.2628L26.0231 27.7861C26.2572 28.0199 26.5746 28.1511 26.9054 28.1509C27.2363 28.1507 27.5535 28.0191 27.7873 27.785C28.0212 27.5509 28.1524 27.2335 28.1522 26.9026C28.152 26.5718 28.0204 26.2546 27.7863 26.0207L20.263 18.4997L27.7863 10.9786Z" fill="#E22626"/>
                          <circle cx="63.5" cy="18.5" r="18.0978" fill="white" stroke="#4BE226" stroke-width="0.804348" style={{cursor:"pointer"}} onClick={()=>{Approve(res.id);console.log("hello1")}}/>
                          <path d="M60 27.8L52 19.8L54.8 17L60 22.2L73.2 9L76 11.8L60 27.8Z" fill="#4BE226"/>
                        </svg>



                      
                      </div>

                      <div>{res.type}</div>
                    </div>

                        )
                      }):<>
                      <div>
                      <div className='d-flex ' style={{justifyContent:"center",alignItems:"center"}}>
                     <Lottie animationData={lotti} style={{width:"50%",height:"15rem",marginLeft:"0rem",}}/>
                        </div>
                      {/* <h6 style={{color:"red"}}>No Data Found</h6> */}
                      </div>
                     
                     </>
                    }
</div>
                   
                  </div>

                </div>
            </div>

          </div>
        </div>

           {/* offcanvas started  */}
       <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight1" aria-labelledby="offcanvasRightLabel" style={{width:'50%'}} >
  <div class="offcanvas-header">
    {/* <h5 class="offcanvas-title" id="offcanvasRightLabel">Offcanvas right</h5> */}
    <button type="button" class="btn-close offcanvasCross" data-bs-dismiss="offcanvas" aria-label="Close" ></button>
  </div>
  <div class="offcanvas-body">
    <div className='container' >
    {
      Data.length>0?
       Data?.map((res,key)=>{
        return(
          <>
          <div className='row' >
          <div className='col-lg-12' >
  
              <div className='col-lg-8 ' >
              <h1 className='offcanvasH1' >Personal Details</h1>
              <div className='d-flex align-items-center mt-3 ' >
                <FaUser className='me-4 offcanvasText' />
                <span className='offcanvasText'>{res.name}</span>
              </div>
              <div className='d-flex align-items-center mt-3 ' >
                <FaEnvelope className=' offcanvasText'/>
                <span className='offcanvasText ms-4' > {res.email}</span>
              </div>
              <div className='d-flex align-items-center mt-3 ' >
                <BsTelephoneFill className='me-4 offcanvasText' style={{transform:'rotate(280deg)'}} />
                <span className='offcanvasText' >{res.contact}</span>
              </div>
              </div>
  
              <div>
                <h1 className='offcanvasShopDetails mt-4' >Shop Details</h1>
                <div className='mt-4' >
                  <h6 className='shopNameHeading' >Shop Name</h6>
                  <h3 className='shopName' >{res.shop_name}</h3>
                </div>
                <div className='mt-4 d-flex justify-content-between' >
                  <div>
                    <h6 className='aadharText' >Udhyam Aadhar No.</h6>
                    <h3 className='aadharNo' >{res.udhyam_aadhar}</h3>
                  </div>
                  <div>
                    <h6 className='gstText' >GST No.</h6>
                    <h3 className='gstNo' >{res.gst}</h3>
                  </div>
                </div>
                <div className='mt-4'>
                  <h6 className='addressText' >Shop Address</h6>
                  <h3 className='addressInof' >{res.shop_address}</h3>
                </div>
  
                
                <div className='mt-4'>
                  <h6 className='addressText' >Shop Photo  </h6>
                  <img src={`https://www.scraponwheels.com/public/Profile/${res.shop_photo}`}  style={{width:'50%',borderRadius:'5px'}}/>
                </div> 
              </div>
  
          </div>
  
        </div>
          </>
        )
       }):<><div><h6 style={{color:"red"}}>No Data Found</h6></div></>
    }

    </div>
  </div>
</div>
   </>
  )
}

export default RequestsButtonPage

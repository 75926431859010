import React,{ useEffect, useState }  from 'react'
import { HiUser } from 'react-icons/hi'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FaUser } from 'react-icons/fa';
import { BsTelephoneFill } from 'react-icons/bs';
import { IoLocationSharp } from 'react-icons/io5';
import { AiOutlineSearch } from 'react-icons/ai';
import userImg from "../assets/Ellipse 131.png"
import lotti from '../assets/animation_llaix5r2.json';
import Lottie from "lottie-react";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
const OrdersData = () => { 

  const Navigate = useNavigate() 
  
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const [Confirm, setConfirm] = useState() 
    const [name, setname] = useState("");
    console.log(name);
    const [Ass, setAss] = useState([]);
    const [value, setValue] = React.useState(0);
    const [Assigns, setAssign] = useState();
    const [AssignId, setAssignId] = useState();
    const [AssignIds, setAssignIds] = useState();
    //const [SelectId, setSelectId] = useState();
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const [OrderDetails, setOrderDetails] = useState([])
    const [card, setCard] = useState([]);
    const [card1, setCard1] = useState([]);
    const [Details, setDetails] = useState([]); 

    const Apiincomingorders = async() =>{
      try {
        var formdata = new FormData();
        formdata.append("token", localStorage.getItem("token"));
        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };
        
        fetch("https://www.scraponwheels.com/api/admin/incoming_orders", requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result);
          if(result.status=="001"){
            setCard(result.message);
          }})
          .catch(error => console.log('error', error));
        
      } catch (error) {
        console.log(error);
      }
      
    }
    const Apiassignedorders = async() =>{
      try {
        var formdata = new FormData();
        formdata.append("token", localStorage.getItem("token"));
        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };
        
        fetch("https://www.scraponwheels.com/api/admin/assigned_orders", requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result);
          if(result.status=="001"){
            setCard1(result.message);
          }})
          .catch(error => console.log('error', error));
        
      } catch (error) {
        console.log(error);
      }
    
    }

    const AssginOrder = async(id,subid) =>{
      

      var formdata = new FormData();
      formdata.append("token", localStorage.getItem("token"));
      formdata.append("id", id);
      formdata.append("subcategory_id", subid);

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch("https://www.scraponwheels.com/api/admin/assigning_orders", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result,"data is here");
        if(result.status=="001"){
         setAss(result.message);
         setConfirm(result.selected);
       
        }
      else{
        toast(result.message);
      }})
        .catch(error => console.log('error', error));
    }

    const Order = async()=>{
      var formdata = new FormData();
      console.log(AssignIds,AssignId)
      formdata.append("token", localStorage.getItem("token"));
      formdata.append("orderid", AssignIds);
      formdata.append("id", AssignId);
      
      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };
      
      fetch("https://www.scraponwheels.com/api/admin/assigning_orders_select", requestOptions)
        .then(response => response.json())
        .then(result => {console.log(result);
          if(result.status=="001"){
             toast(result.message);
             setShow(false);
             Apiincomingorders();
             setAssignId();
             setAss([]);
             setAssignIds();
             setConfirm();
             Apiassignedorders();
          }
          else if(result.status=="009"){
            Navigate("/");
          }
          else{
            toast(result.message);
          }
        }
        )
        .catch(error => console.log('error', error));
    }

    const Deatils = (id)=>{
      console.log(id);
     // setSelectId(id);
      var formdata = new FormData();
      formdata.append("token", localStorage.getItem("token"));
      formdata.append("id", id);

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch("https://www.scraponwheels.com/api/admin/assigned_orders_details", requestOptions)
        .then(response => response.json())
        .then(result => {console.log(result);
          if(result.status=="001"){
             setDetails(result.assigned_data);
             setOrderDetails([result.initiator_data]);
             toast(result.message);
          }
          else{
             toast(result.message);
          }
        })
        .catch(error => console.log('error', error));
    }
 

    const Search = async(names)=>{
      try {
        var formdata = new FormData();
        formdata.append("token", localStorage.getItem("token"));
        formdata.append("id", AssignIds);
        formdata.append("name", names);

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };

        fetch("https://www.scraponwheels.com/api/admin/assigning_orders_search", requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result);
            if(result.status=="001"){
              setAss(result.message)
            }
            else{
              toast(result.message);
            }
          })
          .catch(error => console.log('error', error));
      } catch (err) {
        console.log(err);
      }
    }




      
   

    useEffect (()=>{
      Apiincomingorders();
    },[])

    useEffect (()=>{
      Apiassignedorders();
    },[]);

    useEffect(() => {
      if(!localStorage.getItem("token")){
          Navigate("/");
      }
      else{
        
      }
    }, []);

  return (
    <>


    <ToastContainer/>
        <div className='d-flex justify-content-end align-items-center mt-4' >
        <HiUser className='userIcon p-2' />
      </div> 

      <div className='d-flex align-items-center justify-content-between mt-5' >
        <h1 className='vendorHeading' >Orders </h1>
        </div>






        <div className='container-fluid' >
        <div className='row' >
          <div className='col-lg-12' >
           <Box sx={{ width: '100%' }}>
             <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
               <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                 <Tab className='tabNavs' label="Incoming Orders" {...a11yProps(0)} />
                 <Tab className='tabNavs' label="Assigned" {...a11yProps(1)} />
               </Tabs>
             </Box>
             <TabPanel value={value} index={0}>
       
        <div className='' style={{height:'60vh',overflowY:'scroll'}} >
            <div className='row m-0'>
            {
              card?.length>0?
                card.map((item,res)=>{
                  return(
                <div key={res} className='col-lg-3 mb-3' >
                    <div className='card h-100' >
                        <div className='card-body' >
                            <div className='row' >
                                <div className='col-lg-1' >
                                <FaUser/>
                                </div>
                                <div className='col-lg-10 ms-2'>
                                    <span className='cardText' >{item.name}</span>
                                </div> 
                            </div>
                            <div className='row' >
                                <div className='col-lg-1' >
                                <BsTelephoneFill/>
                                </div>
                                <div className='col-lg-10 ms-2'>
                                    <span className='cardText' >{item.mobile}</span>
                                </div> 
                            </div>
                            <div className='row' >
                                <div className='col-lg-1' >
                                <IoLocationSharp/>
                                </div>
                                <div className='col-lg-10 ms-2'>
                                    <span className='cardText' >{item.shop_address}</span>
                                </div> 
                            </div>
                          <div className='mt-3' >
                            <div className='col-lg-12' >
                              <h6>₹ {item.est_value}</h6>
                              {/* <h2>{item.price}</h2> */}
                            </div>
                          </div>

                            <div className='row mt-3' >
                              <div className='col-10 m-auto'>
                                <button className=' cardButton py-2 w-100'
                                 onClick={()=>{AssginOrder(item.id,item.subcategory_id);handleShow();setAssignIds(item.id)}}>Assign Order -</button>
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
                )
                }):<div>
                <div className='d-flex ' style={{justifyContent:"center",alignItems:"center"}}>
       <Lottie animationData={lotti} style={{width:"50%",height:"15rem",marginLeft:"0rem",}}/>
                  </div>
                {/* <h6 style={{color:"red"}}>No Data Found</h6> */}
                </div>
               }
          
            </div>
        </div>
             </TabPanel>
             
             <TabPanel value={value} index={1}>
             <div className='' style={{height:'60vh',overflowY:'scroll'}} >
            <div className='row m-0'>
            {
              card1?.length>0?
                card1.map((item,res)=>{
                  return(
                <div key={res} className='col-lg-3 mb-3' >
                    <div className='card h-100' >
                        <div className='card-body' >
                            <div className='row' >
                                <div className='col-lg-1' >
                                <FaUser/>
                                </div>
                                <div className='col-lg-10 ms-2'>
                                    <span className='cardText' >{item.name}</span>
                                </div> 
                            </div>
                            <div className='row' >
                                <div className='col-lg-1' >
                                <BsTelephoneFill/>
                                </div>
                                <div className='col-lg-10 ms-2'>
                                    <span className='cardText' >{item.mobile}</span>
                                </div> 
                            </div>
                            <div className='row' >
                                <div className='col-lg-1' >
                                <IoLocationSharp/>
                                </div>
                                <div className='col-lg-10 ms-2'>
                                    <span className='cardText' >{item.shop_address}</span>
                                </div> 
                            </div>
                            <hr/>
                          <div className='mt-3'>
                            <div className='col-lg-12' >
                              <div className='d-flex align-items-center column-gap-2' >
                              <div style={{backgroundColor:"#000",height:"30px",borderRadius:"50%",width: "12%",color:"#fff",}}>
                               <p style={{marginTop:"3px",textTransform:"uppercase",textAlign:'center'}}>{item.name?.split("",1)}</p>
                              </div>
                                {/* <img src={userImg} alt='user image' style={{width:"2rem"}} /> */}
                                <span className='cardText' >{item.name}</span>
                              </div>
                            </div>
                          </div>

                            <div className='row mt-3' >
                              <div className='col-10 m-auto'>
                                <button  data-bs-toggle="modal" data-bs-target="#staticBackdropAssiOrdersMod" className=' cardButton py-2 w-100' onClick={()=>{Deatils(item.id)}}>View Details </button>
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
                )
                }):<div>
                <div className='d-flex ' style={{justifyContent:"center",alignItems:"center"}}>
                    <Lottie animationData={lotti} style={{width:"50%",height:"15rem",marginLeft:"0rem",}}/>
                </div>
                {/* <h6 style={{color:"red"}}>No Data Found</h6> */}
                </div>
               }
          
            </div>
        </div>
             </TabPanel>
           </Box>
          </div>
        </div>
      </div>



      <Offcanvas show={show} onHide={handleClose} placement="end" style={{width:"40%"}}>
        <Offcanvas.Header closeButton>
          
        </Offcanvas.Header>
        <Offcanvas.Body>
        <div className='container' >
        <div className='row' >
                  <div className='col-lg-8' >
                    <div class="input-group mb-3" style={{borderRadius:'6px'}} >
                      <span style={{backgroundColor:'#f1f1f1'}} class="input-group-text border-0" id="basic-addon1"><AiOutlineSearch/></span>
                      <input style={{backgroundColor:'#f1f1f1'}} type="text" class="form-control border-0" placeholder="Search..." aria-label="Username" aria-describedby="basic-addon1" onChange={(e)=>{setname(e.target.value);Search(e.target.value)}}  />
                    </div>
                  </div>
        </div>
    
        <div className='row' >
          <div className='col-lg-12' >
                <div style={{fontFamily:'Poppins',fontStyle:'normal',fontSize:'20px',borderBottom:'1px solid #eaeaea'}} className='d-flex justify-content-between align-items-center px-3' >
                   <p>Name</p>
                   <p>Contact No.</p>
                   <p>Action</p>
                </div>  
    
                <div style={{fontFamily:'Poppins',fontStyle:'normal',fontSize:'16px',color:'#000',height:'62vh',overflowY:'scroll'}} >
                <div className='d-flex justify-content-between align-items-center mt-2 px-2' >
                <p>{Confirm?.name}</p>
                <p>{Confirm?.mobile}</p>
                <p className='p-1' style={{border:'1px solid #040404',cursor:'pointer',borderRadius:'5px',background:Assigns==0?"green":"#fff",color:"#000"}} onClick={()=>{setAssign(0);setAssignId(Confirm?.id)}}>Assign Order</p>
                 </div>
                {  
                  Ass?.map((res,key)=>{
                    return(
                      <>
                      <div className='d-flex justify-content-between align-items-center mt-2 px-2' >
                      <p>{res.name}</p>
                      <p>{res.mobile}</p>
                      <p className='p-1' style={{border:'1px solid #040404',cursor:'pointer', borderRadius:'5px',background:Assigns==key+1?"green":"#fff",color:"#000"}} onClick={()=>{setAssign(key+1);setAssignId(res.id)}}>Assign Order</p>
                       </div>
                      </>
                    )
                  })
                }
                  
              
                </div> 
                
                
          </div>
        </div>
    
        <div className='row' >
        <div className='col-lg-3 m-auto' >
                   <button  className='w-100 py-2 ' style={{fontFamily:'Inter',fontStyle:'normal',fontSize:'16px',backgroundColor:'#FF8604',borderRadius:'4px',color:'#fff',border:'none'}} onClick={()=>{Order()}}  >Confirm</button>
                </div>
    
        </div>
      </div>
    
        </Offcanvas.Body>
      </Offcanvas>

      




{/* modal started  */}
<div class="modal fade" id="staticBackdropIncomingOrderButton" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style={{backdropFilter:"blur(10px)"}}>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      {/* <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1>
        <button type="button" class="btn-close"></button>
      </div> */}
      <div class="modal-body">
      <div className='row' >
        <div className='col-9 m-auto' >
               <h3 style={{textAlign:'center'}} >Order Has Been Assigned Successfully !</h3>
        </div>

        <div className='col-8 m-auto mt-4' >
               <button  data-bs-dismiss="modal" aria-label="Close" style={{fontFamily:'Inter',fontStyle:'normal',fontSize:'16px',color:'#fff',backgroundColor:'#FF8604',border:'none',borderRadius:'5px'}} className='w-100 py-2' >Back To Dashboard</button>

        </div>
      </div>

      </div>
     
    </div>
  </div>
</div>
{/* modal end  */}



{/* modal started for assigned orders  */}
<div class="modal fade" id="staticBackdropAssiOrdersMod" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button type="button" class="btn-close m-0" data-bs-dismiss="modal" aria-label="Close" ></button>
      </div>
      <div class="modal-body m-0 pt-0 ">
      {
        Details?.length>0?
        Details?.map((res)=>{
          return(
            <>
            <div>

            <div>
              <h2 style={{fontFamily:"Poppins",fontStyle:"normal",fontSize:"26px"}} >Assigned To</h2>
           </div>
          
          <div>
            <div className='d-flex align-items-center column-gap-2 mt-3' >
              <FaUser/>
              <span className='cardText' >{res.assign_name}</span>
            </div>
           
           <div className='d-flex align-items-center column-gap-2 mt-3' >
              <IoLocationSharp/>
              <span className='cardText' >{res.assign_addr}</span>
           </div>
  
           <div className='d-flex align-items-center column-gap-2 mt-3' >
              <BsTelephoneFill/>
              <span className='cardText' >{res.assign_phone}</span>
            </div>
  
            <div className='d-flex align-content-center column-gap-3 mt-3' >
              <div>
                <h5 style={{fontFamily:"Poppins",fontStyle:"normal",fontSize:"16px",color:"#454545"}}>Udhyam Aadhar No.</h5>
                <p style={{fontFamily:"Poppins",fontStyle:"normal",fontSize:"18px",color:"#000"}}>{res.aadhar}</p>
              </div>
              <div>
                <h5 style={{fontFamily:"Poppins",fontStyle:"normal",fontSize:"16px",color:"#454545"}}>GST No.</h5>
                <p style={{fontFamily:"Poppins",fontStyle:"normal",fontSize:"18x",color:"#000"}}>{res.gst}</p>
              </div>
            </div>
  
          </div>
  
          <hr/>
  
  
         
          </div>
            </>
          )
        }):<><div><h6 style={{color:"red"}}>Data Not Found</h6></div></>
      }
     {
      OrderDetails?.map((res)=>{
        return(
          <>
          <div>
          <h2 style={{fontFamily:"Poppins",fontStyle:"normal",fontSize:"26px"}} >Order Details</h2>
          </div>

          <div>
            <div className='d-flex align-items-center column-gap-2 mt-3' >
              <FaUser/>
              <span className='cardText' >{res.name
              }</span>
            </div>
          
          <div className='d-flex align-items-center column-gap-2 mt-3' >
              <IoLocationSharp/>
              <span className='cardText' >{res.shop_address}</span>
          </div>

          <div className='d-flex align-items-center column-gap-2 mt-3' >
              <BsTelephoneFill/>
              <span className='cardText' >{res.mobile
              }</span>
            </div>

          </div>

          </>
        )
      })
      }
      </div>
      <div class="modal-footer d-flex justify-content-start">
      <div className='d-flex flex-column p-2' style={{border:'1px solid #bfbfbf',borderRadius:'5px'}} >
                        <h3 style={{fontFamily:'Poppins',fontSize:'20px',color:'#000',}} >Total Amount</h3>
                        <h3 style={{fontFamily:'Poppins',fontSize:'32px',color:'#000'}} >₹ {Details[0]?.est_value}</h3>
                    </div>
      </div>
    </div>
  </div>
</div>
{/* modal end for assigned orders  */}



     


    </>
  )
}

export default OrdersData;
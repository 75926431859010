
import React,{ useEffect, useState }  from 'react'
import "./RatesData.css"
import {HiUser} from "react-icons/hi";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {NavLink, useNavigate} from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import lotti from '../assets/animation_llaix5r2.json';
import Lottie from "lottie-react";
 


function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    > 
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
} 


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const RatesData = () => { 

  const [name, setname] = useState("");
  console.log(name);

  const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };


    const [card, setCard] = useState([]);
    const [card12, setCard12] = useState([]);

    const Apicategory = async() =>{
      try {
        var formdata = new FormData();
        formdata.append("token", localStorage.getItem("token"));
        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };
        
        fetch("https://www.scraponwheels.com/api/admin/category", requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result);
          if(result.status=="001"){
            setCard(result.message);
          }})
          .catch(error => console.log('error', error));
        
      } catch (error) {
        console.log(error);
      }
      
    }
    const Apiadd = async() =>{
      try {
        var formdata = new FormData();
        formdata.append("token", localStorage.getItem("token"));
        formdata.append("name", name);
        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };
        
        fetch("https://www.scraponwheels.com/api/admin/category_add", requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result);
            if(result.status=="001"){
              Apicategory();
              toast(result.message);
            }})
          .catch(error => console.log('error', error));
        
      } catch (error) {
        console.log(error);
      }
      
    }

      let navigate = useNavigate(); 

    useEffect (()=>{
      Apicategory();
    },[]);

    useEffect(() => {
      if(!localStorage.getItem("token")){
        navigate("/");
      }
      else{
        
      }
    }, []);
    
   

  return (
   <>
   <ToastContainer/>
    <div className='d-flex justify-content-end align-items-center mt-4' >
        <HiUser className='userIcon p-2' />
      </div> 

      <div className='d-flex align-items-center justify-content-between mt-5' >
        <h1 className='vendorHeading' > {value==1?"Live Rates":"Retail Rates"} </h1>
    
        
        </div> 

        <div className='container-fluid' >
          <div className='row' >
            <div className='col-lg-12' >
            <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab className='tabNavs' label="Retail Rates" {...a11yProps(0)} />
          <Tab className='tabNavs' label="Live Rates" {...a11yProps(1) } />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
          <div  style={{height:'60vh',overflowY:'scroll'}} >
          <div className='row'>
          {
            card?.length>0?
                card.map((item,res)=>{
                  return(
            <div key={res} className='col-lg-3 mt-3'> 
            <NavLink to={`/RetailRatesElectronics/${item.id}/${item.name}/${"Retail Rates"}`} style={{textDecoration:'none'}} >
                <div className='card myCard' >
                    <p className=' px-3 mt-3' h2style={{color:'#000',fontFamily:'Inter',fontSize:'16px'}} >{item.name}</p>
                </div>
            </NavLink>
            </div>
            )
                }):<div>
                <div className='d-flex ' style={{justifyContent:"center",alignItems:"center"}}>
       <Lottie animationData={lotti} style={{width:"50%",height:"15rem",marginLeft:"0rem",}}/>
                  </div>
                {/* <h6 style={{color:"red"}}>No Data Found</h6> */}
                </div>
               }
               </div>
          </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <div style={{height:'60vh',overflowY:'scroll'}}>
      <div className='row'>
          {
            card?.length>0?
                card.map((item,res)=>{
                  return(
            <div key={res} className='col-lg-3 mt-3'> 
            <NavLink to={`/RetailRatesElectronics/${item.id}/${item.name}/${"Live Rates"}`} style={{textDecoration:'none'}} >
                <div className='card myCard'>
                    <p className=' px-3 mt-3' h2style={{color:'#000',fontFamily:'Inter',fontSize:'16px'}} >{item.name}</p>
                </div>
            </NavLink>
            </div>
            )
                }):<div>
                <div className='d-flex ' style={{justifyContent:"center",alignItems:"center"}}>
       <Lottie animationData={lotti} style={{width:"50%",height:"15rem",marginLeft:"0rem",}}/>
                  </div>
                {/* <h6 style={{color:"red"}}>No Data Found</h6> */}
                </div>
               }
          </div>
          </div>
      </TabPanel>
    </Box>
            </div>
          </div>
        </div>






        {/* modal started  */}


<div class="modal fade" id="staticBackdropDisButtton1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h1 style={{fontFamily:'Inter',fontStyle:'normal',fontSize:'32px',color:'#000'}} >Add District</h1>
        <p className='mt-3' style={{fontFamily:'Inter',fontSize:'20px',fontStyle:'normal',color:'#000'}} >Name</p>
        <div className='' >
            <input type='text' className='px-3 py-2' placeholder='Name... ' style={{width:'100%',background:'#FAFAFA',borderRadius:'5px',border:'none'}} value={name} onChange={(e)=>{setname(e.target.value)}} />
        </div>
        <div className='col-5 m-auto mt-5' >
            <button data-bs-dismiss="modal"  onClick={()=>{Apiadd()}} aria-label="Close" className='py-2' style={{backgroundColor:'#040404',borderRadius:'5px',color:'#fff',fontFamily:'Roboto',fontStyle:'normal',fontSize:'14px',width:'100%',border:'none'}} >Confirm</button>

        </div>
      </div>
      
    </div>
  </div>
</div>
        {/* modal end  */}
   </>
  )
}

export default RatesData;

import {React,useState} from 'react'
import img1 from "../assets/img1.png"
import img2 from "../assets/13 1.png"
import {NavLink} from "react-router-dom"
import {AiFillEye} from "react-icons/ai";
import {BiSolidLockAlt} from "react-icons/bi";

const NewPassword = () => {

  const [name, setname] = useState("");
  console.log(name);

  const [name1, setname1] = useState("");
  console.log(name1);

  return (
    <>
        <div style={{background:'#000'}} >
            <div className='container m-0 p-0' >
                <div className='row align-items-center' >

                    <div className='col-lg-6 col-md-6' >
                        <img src={img1} alt='nn' style={{width:'100%',height:'100vh'}} />
                    </div>

                    <div className='col-lg-6 col-md-6' >
                        <div className='d-flex flex-column justify-content-between' >
                              <div className='mb-5' >
                                <img src={img2} alt='nn' style={{width:'179.2px'}} />
                              </div>          

                              <div className='mt-0' >
                                <h1 style={{fontFamily:'Montserrat',fontSize:'36px',fontStyle:'normal',color:'#E3E3E3'}} >Confirm Password</h1>
                              </div>

                              <div className='mt-3' >
                                <label for='userEmail' style={{fontFamily:'Montserrat',fontStyle:'normal',fontSize:'14px',color:'#E3E3E3'}} >Enter New Password</label>
                                    <div class="input-group mb-3" style={{border:'1px solid #BFBFBF',borderRadius:'5px',fontFamily:'Montserrat',fontStyle:'normal',fontSize:'14px',}} >
                                      <span class="input-group-text border-0" id="basic-addon1" style={{background:'#000',color:'#bdbdbd'}} ><BiSolidLockAlt/></span>
                                      <input id='userEmail' style={{background:'#000',color:'#bdbdbd'}} type="password" class="form-control border-0 emPlace" placeholder="00000000" aria-label="Username" aria-describedby="basic-addon1" onChange={(e)=>{setname(e.target.value)}} />
                                      <span class="input-group-text border-0" style={{background:'#000',color:'#bdbdbd',cursor:'pointer'}}><AiFillEye/></span>
                                    </div>

                              </div>
                              <div className='mt-3' >
                                <label for='userEmail' style={{fontFamily:'Montserrat',fontStyle:'normal',fontSize:'14px',color:'#E3E3E3'}} >Enter New Password</label>
                                    <div class="input-group mb-3" style={{border:'1px solid #BFBFBF',borderRadius:'5px',fontFamily:'Montserrat',fontStyle:'normal',fontSize:'14px',}} >
                                      <span class="input-group-text border-0" id="basic-addon1" style={{background:'#000',color:'#bdbdbd'}} ><BiSolidLockAlt/></span>
                                      <input id='userEmail' style={{background:'#000',color:'#bdbdbd'}} type="password" class="form-control border-0 emPlace" placeholder="00000000" aria-label="Username" aria-describedby="basic-addon1" onChange={(e)=>{setname1(e.target.value)}} />
                                      <span class="input-group-text border-0" style={{background:'#000',color:'#bdbdbd',cursor:'pointer'}}><AiFillEye/></span>
                                    </div>

                              </div>

                              {/* <div className='m-auto mt-3' >

                                <p style={{textAlign:'center',fontFamily:'Montserrat',fontSize:'20px',fontStyle:'normal',color:'#E3E3E3'}} >We Have Sent You An OTP On Registered<br/>
                                E-mail Address </p>
                                <p style={{textAlign:'center',fontFamily:'Montserrat',fontSize:'14px',fontStyle:'normal',color:'#bdbdbd'}}>NarayanMurthy@gmail.com, <span style={{color:'#FF8604',cursor:'pointer'}} >Change E-mail address</span>  </p>
                                
                                <div id="input" className='d-flex justify-content-center align-items-center pt-4  ' >
                                  <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} />
                                </div>

                                <p className='mt-3' style={{textAlign:'center',fontFamily:'Montserrat',fontSize:'14px',fontStyle:'normal',color:'#bdbdbd'}}>Don’t Receive the OTP ? <span style={{color:'#FF8604',cursor:'pointer'}} >Resend OTP</span>  </p>

                              </div>  */}

                              <div className='mt-5' >
                                      <NavLink to='/PasswordResetSucc' >
                                        <button className='w-100 py-3' style={{color:'#FF8604',fontFamily:'Montserrat',fontStyle:'normal',fontSize:'14px',border:'1px solid #FF8604',borderRadius:'5px',background:'#000'}} >Verify and Proceed</button>
                                      </NavLink>
                                    </div>
                        </div>
                    </div>
                    
                </div>

            </div>
    </div>
    </>
  )
}

export default NewPassword

import React,{useEffect} from 'react'
import OrdersData from './OrdersData'
import Sidebar from '../Sidebar'
import { useNavigate } from 'react-router-dom'

const Orders = () => {
   let Navigate = useNavigate();

  useEffect(() => {
    if(!localStorage.getItem("token")){
     Navigate("/");
    }
    else{
     
    }
}, []);
  return (
   <>
    <div className='container-fluid' >
          <div className='row'>
            <div className='col-lg-2 sidebar' >
              <Sidebar/>
            </div>

            <div className='col-lg-10' >
              <OrdersData/>
            </div>
          </div>
        </div>
   </>
  )
}

export default Orders

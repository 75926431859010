import React from 'react';
import { Route, Routes} from "react-router-dom";
import './App.css'
import Vendors from './Vendors/Vendors';
import RequestsButtonPage from './Vendors/RequestsButtonPage';
import Rates from './Rates/Rates';
import Categories from './Categories/Categories';
import ElectronicsPage from './Categories/ElectronicsPage';
import Orders from './Orders/Orders';
import RetElectronics from './Rates/RetElectronics';
import Sales from './Sales/Sales';
import SignUp from './SignUp/SignUp';
import SignUpVerify from './SignUp/SignUpVerify';
import SignUpVerifySucc from './SignUp/SignUpVerifySucc';
import Login from './Login/Login';
import LoginVerify from './Login/LoginVerify';
import ForgotPassword from './Login/ForgotPassword';
import ForgotPasswordOtp from './Login/ForgotPasswordOtp';
import NewPassword from './Login/NewPassword';
import PasswordResetSucc from './Login/PasswordResetSucc';

function App() {
  

  return (
    <>
       <Routes>
        
        <Route path='/' element={<Login/>}/>
        <Route path='/SignUpVerification' element={<SignUpVerify/>}/>
        <Route path='/LoginVerification' element={<LoginVerify/>}/>
        <Route path='/SignUpVerificationSucc' element={<SignUpVerifySucc/>}/>
        <Route path='/Home' element={<Vendors/>}/>
        <Route path='/Request' element={<RequestsButtonPage/>}/>
        <Route path='/Rates' element={<Rates/>}/>
        <Route path='/Categories' element={<Categories/>}/>
        <Route path='/Electronics/:id/:name123' element={<ElectronicsPage/>}/>
        <Route path='/Orders' element={<Orders/>}/>
        <Route path='/RetailRatesElectronics/:id/:name/:RR' element={<RetElectronics/>}/>
        <Route path='/Sales' element={<Sales/>}/>
        <Route path='/ForgotPassword' element={<ForgotPassword/>}/>
        <Route path='/ForgotPasswordOtp' element={<ForgotPasswordOtp/>}/>
        <Route path='/NewPassword' element={<NewPassword/>}/>
        <Route path='/PasswordResetSucc' element={<PasswordResetSucc/>}/>
        
     </Routes>
    </>
  )
}

export default App;

import React from 'react';
import "./Sidebar.css";
import mainLogo from "../src/assets/13 1.png";
import {RxDashboard} from "react-icons/rx"
import {RiGroupLine} from "react-icons/ri"
import {BiMoney} from "react-icons/bi"
import {FaBoxOpen} from "react-icons/fa"
import {TbCategory2} from "react-icons/tb"
import {RiMoneyDollarBoxLine} from "react-icons/ri"
import {BiCalendarStar} from "react-icons/bi"
import {MdManageAccounts} from "react-icons/md"
import {BiUserCircle} from "react-icons/bi"
import { NavLink, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Sidebar = () => {
    let location = useLocation();

    let Navigate = useNavigate();

    
     const Api = ()=>{
        var formdata = new FormData();
        formdata.append("token", localStorage.getItem("token"));

        var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
        };

        fetch("https://www.scraponwheels.com/api/admin/logout", requestOptions)
        .then(response => response.json())
        .then(result => {console.log(result);
        if(result.status=="001"){
            Navigate("/");
            localStorage.removeItem("token")
        }
    else{
        Navigate("/");
        localStorage.removeItem("token")
    }})
        .catch(error => console.log('error', error));
     }


  return (
    <div data-scroll-section>

        {/* scrap on wheel main logo started */}
        <div>
            <img src={mainLogo} alt='scrap on wheels logo' className='w mt-5' style={{cursor:'pointer'}} />
        </div>
        {/* scrap on wheel main logo end  */}



        {/* sidebar list started  */}
          <div className='d-flex flex-column mainList mt-5 align-items-start px-4' >
              {/* <div className='mainListItems d-flex justify-content-center align-items-center '  >
                  <RxDashboard/>
                  <span>Dashboard</span>
              </div> */}
              
              <NavLink to="/Home" style={{textDecoration:'none',backgroundColor:location.pathname=="/Home"?"#FF8604":"",color:location.pathname=='/Home'?'#000000':'#b4b4b4',padding:location.pathname=='/Home'?'1rem 1rem':"",borderRadius:"10px"}} >
              <div className='mainListItems d-flex justify-content-center align-items-center' >
                  <RiGroupLine/>
                  <span>Vendors</span>
              </div>
              </NavLink>

              <NavLink to="/Rates" style={{textDecoration:'none',backgroundColor:location.pathname=="/Rates"?"#FF8604":"",color:location.pathname=='/Rates'?'#000000':'#b4b4b4',padding:location.pathname=='/Rates'?'1rem 1rem':"",borderRadius:"10px"}} >
              <div className='mainListItems d-flex justify-content-center align-items-center ' >
                  <BiMoney/>
                  <span>Rates</span>
              </div>
              </NavLink>
              <NavLink to="/Orders" style={{textDecoration:'none',backgroundColor:location.pathname=="/Orders"?"#FF8604":"",color:location.pathname=='/Orders'?'#000000':'#b4b4b4',padding:location.pathname=='/Orders'?'1rem 1rem':"",borderRadius:"10px"}} >
              <div className='mainListItems d-flex justify-content-center align-items-center ' >
                  <FaBoxOpen/>
                  <span>Orders</span>
              </div>
              </NavLink>
              <NavLink to="/Categories" style={{textDecoration:'none',backgroundColor:location.pathname=="/Categories"?"#FF8604":"",color:location.pathname=='/Categories'?'#000000':'#b4b4b4',padding:location.pathname=='/Categories'?'1rem 1rem':"",borderRadius:"10px"}} >
              <div className='mainListItems d-flex justify-content-center align-items-center ' >
                  <TbCategory2/>
                  <span>Categories</span>
              </div>
                  </NavLink>
                  <NavLink to="/Sales" style={{textDecoration:'none',backgroundColor:location.pathname=="/Sales"?"#FF8604":"",color:location.pathname=='/Sales'?'#000000':'#b4b4b4',padding:location.pathname=='/Sales'?'1rem 1rem':"",borderRadius:"10px"}} >
              <div className='mainListItems d-flex justify-content-center align-items-center '>
                  <RiMoneyDollarBoxLine/>
                  <span>Sales</span>
              </div>
              </NavLink>
              <div className='mainListItems d-flex justify-content-center align-items-center ' >
                  <BiCalendarStar style={{color:"red"}}/>
                  <span style={{color:"red"}} onClick={()=>{Api()}}>Logout</span>
              </div>
              {/* <div className='mainListItems d-flex justify-content-center align-items-center ' >
                  <MdManageAccounts/>
                  <span>Accounts</span>
              </div>
              <div className='mainListItems d-flex justify-content-center align-items-center ' >
                  <BiUserCircle/>
                  <span>Users</span>
              </div> */}


          </div>
        {/* sidebar list end  */}
    </div>
  )
}

export default Sidebar
